<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i> แก้ไขข้อมูลผูกสินค้ากับร้านค้า</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <form-store-mapping-product />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formStoreMappingProduct from './form.store_mapping_product';
export default {
  name: 'store_mapping_product-view-page-store_mapping_product-edit',
  components: {
    formStoreMappingProduct
  }
}
</script>
